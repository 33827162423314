@import "../../styles/theme.scss";

.foot-wrapper {
  width: 100%;
  height: max(256px, 16vh);
  display: block;
  margin: 0 auto;
  padding: 15px;
  background: #fff;
  position: relative;
  top: 0;
  left: 0;
}
footer .foot-panel {
  margin: 15px auto;
}
footer .foot-box {
  display: flex;
  align-self: center;
  justify-content: space-around;
  padding: 0 15px;
}
.select {
  position: relative;
  background: #fff;
  height: 32px;
  width: 160px;
  margin: 15px auto;
  cursor: pointer;
}
.select:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  pointer-events: none;
}

select {
  width: 100%;
  height: 100%;
  z-index: 1;
  background: transparent;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0 0 0 10px;
  cursor: pointer;
  font: 300 16px/1.5 "Roboto", sans-serif;
  text-indent: 5px;
  color: rgba(0, 0, 0, 0.5);
}
.select:hover:after {
  color: #ffad60;
}
.select:hover select {
  color: #ffad60;
}
select:focus {
  outline: none;
}
select::-ms-expand {
  display: none;
}
select option {
  font-family: 300 16px/1.5 "Roboto", sans-serif;
}

@include mediaSm {
  .foot-wrapper {
    width: 100%;
    height: auto;
  }
}