@import "../../styles/theme.scss";
@import "../../styles/animate.css";

.img-circle-sm {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}
.search-article {
  padding: 8px;
  height: max(330px, 36vh);
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .search-box {
    margin-top: 120px;
    max-width: 300px;
    position: relative;

    .search-input {
      width: 100%;
      padding: 12px 24px;
      color: #ffad60;
      background-color: transparent;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 18px 18px;
      background-position: 95% center;
      border-radius: 50px;
      border: 1px solid #ffad60;
      transition: all 250ms ease-in-out;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      &::placeholder {
        // color: color(#ffad60 a(0.8));
        text-transform: uppercase;
        letter-spacing: 1.5px;
      }
      &:hover,
      &:focus {
        padding: 12px 0;
        outline: 0;
        border: 1px solid transparent;
        border-bottom: 1px solid #ffad60;
        border-radius: 0;
        background-position: 100% center;
      }
    }
    .search-results {
      animation: fadeInUp 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      position: absolute;
      width: 300px;
      left: 0;
      top: 46px;

      .result-item {
        display: block;
        width: 300px;
        height: 30px;
        padding: 5px;
        background: #fff;
        margin-top: 1px;
        color: #000;

        &:hover {
          text-decoration: none;
        }
        .pull-right {
          float: right;
        }
      }
    }
  }
}
.articles {
  min-height: 100vh;
  max-width: 1000px;
  .wrapper {
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none; /* IE 11 */
      scrollbar-width: none; /* Firefox 64 */
    }
  }

  .top-six {
    margin: 15px 0;

    .article-top-six {
      width: 100%;
      min-height: 300px;
      text-align: center;
      padding: 8px;

      h2 {
        margin: 15px 0;
      }
      .wrapper {
        height: 360px;
        .scroll-item {
          margin: 30px 12px;

          &:hover {
            top: 0;
            -webkit-transform: scale(1.4);
            -ms-transform: scale(1.4);
            transform: scale(1.4);
          }

          &:nth-child(odd) {
            top: -25px;
          }

          .scroll-link {
            .scroll-cover {
              height: 200px;
            }
            .scroll-score {
              bottom: 60px;
              font-size: 18px;
              right: 4px;
            }
            .scroll-info {
              background: rgba(0, 0, 0, 0.3);
              position: absolute !important;
              vertical-align: bottom;
              left: 0;
              bottom: 0;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .top-three {
    margin: 30px auto;
    text-align: center;
    h2 {
      margin: 15px 0;
    }

    .top-three-item {
      position: relative;
      display: block;
      margin: 15px auto;
      width: 100%;
      min-width: 180px;
      padding-bottom: 2.25rem;
      text-align: center;

      &:before {
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from($theme-color-1),
          color-stop(20%, $theme-color-3),
          to($theme-color-4)
        ) !important;
        background: linear-gradient(
          to bottom,
          $theme-color-1 0%,
          $theme-color-3 20%,
          $theme-color-4 100%
        ) !important;
      }
      & > * {
        position: relative;
        z-index: 5;
      }
      .inner-wrapper {
        height: 200px;
        vertical-align: bottom;
        margin-bottom: 1rem;
        padding-top: 3rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        -webkit-transition: all 0.3s 0.12s;
        transition: all 0.3s 0.12s;
        background-color: #fff;
        -webkit-box-shadow: 0 1.375rem 2.25rem -0.75rem rgba(64, 64, 64, 0.13);
        box-shadow: 0 1.375rem 2.25rem -0.75rem rgba(64, 64, 64, 0.13);
        & > * {
          position: relative;
          z-index: 5;
        }
        .writer-avatar {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          margin: auto auto 1rem auto;
          overflow: hidden;
          -webkit-transition: all 0.3s 0.12s;
          transition: all 0.3s 0.12s;
        }
        .loc-info {
          color: #6c757d;
          bottom: 0;
          -webkit-transition: color 0.3s 0.12s;
          transition: color 0.3s 0.12s;
          img {
            filter: grayscale(100%);
          }
        }
      }
      .text-writer {
        -webkit-transition: color 0.3s 0.12s;
        transition: color 0.3s 0.12s;
      }
    }
    @each $num in "1" "2" "3" "4" {
      .top-#{$num} {
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 0;
          -webkit-transition: all 0.3s 0.12s;
          transition: all 0.3s 0.12s;
          content: "";
          opacity: 0;
        }
        &:hover:before {
          height: 100%;
          -webkit-box-shadow: 0
            1.375rem
            2.25rem -0.75rem
            rgba(64, 64, 64, 0.13);
          box-shadow: 0 1.375rem 2.25rem -0.75rem rgba(64, 64, 64, 0.13);
          opacity: 1;
        }
        &:hover {
          .inner-wrapper {
            background-color: transparent;
            -webkit-box-shadow: none;
            box-shadow: none;
            .writer-avatar {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }
            .loc-info {
              color: #fff;
              span, i {
                color: #fff;
              }
              img {
                filter: none;
              }
            }
          }
          .text-writer {
            color: #fff;
          }
        }
        .text-writer {
          color: #6c757d;
          padding-bottom: 0.3rem;
          font-size: 1.25rem;
          font-weight: bold;
        }
      }
    }
  }

  .article-category {
    text-align: center;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
    padding-bottom: 15px;
    overflow: hidden;

    .category {
      padding: 15px;
      width: 100%;
      // background-image: url("../../assets/img/splash.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .category-category {
        h2 {
          margin-bottom: 15px;
        }
        .category-flex {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          display: -webkit-flex;
          -webkit-flex-flow: row wrap;
          -webkit-justify-content: space-between;
          // visibility: hidden;
          // &:hover > * {
          //   opacity: 0.4;
          //   transform: scale(0.9);
          // }
          // & > * {
          //   visibility: visible;
          //   transition: opacity 150ms linear 100ms,
          //     transform 150ms ease-in-out 100ms;
          // }
          // & > *:hover {
          //   opacity: 1;
          //   transform: scale(1);
          //   transition-delay: 0ms, 0ms;
          // }
          .category-item {
            background: rgba(0, 0, 0, 0.7);
            margin-right: 8px;
            margin-bottom: 8px;
            padding: 8px 12px;
            vertical-align: middle;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            color: #fff;
            cursor: pointer;

            &:hover,
            &.active {
              background: $theme-color-4;
            }
            &:after {
              content: "";
              clear: both;
            }
          }
        }
      }
    }
    .category-article {
      &.animate {
        .table-moden li {
          animation: fadeOutLeft 7s cubic-bezier(0.01, 1.62, 0.56, 1.58);
        }
      }
    }
  }
}

@include mediaMd {
  .articles {
    .top-three {
      margin: 30px;
    }
  }
}
@include mediaSm {
  .articles {
    .top-three {
      margin: 15px;
    }
  }
}
