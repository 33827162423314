.editor-activity {
  min-height: 180px;
  background: #fff;
  padding: 0 8px;
}
.toolbar-activity {
  background: #ffe0bd;
}
.wrapper-activity {
  max-width: 600px;
}

.img-activity {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 auto;
}
.move-right {
  float: right;
}
.img-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.article-title {
  font-weight: bold;
}
.activity-item {
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  background: #fff;
}
.pull-right {
  float: right;
}
