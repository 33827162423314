.comment-box {
  max-width: 600px;
}
.editor-comment {
  border: 1px solid #343a40;
  background: #fff;
  padding: 0 8px;
  height: 120px;
}
.comment-list-item {
  background: #fff;
  margin: 5px 0;
  padding: 8px;
}
.img-comment {
  width: 64px;
  height: 64px;
}
