@import "src/styles/theme.scss";

$icon-size: 25px;
.auth {
  min-height: 100vh;
  height: 100%;
  padding-top: 90px;
  overflow: hidden;
  position: relative;
  background: radial-gradient(
    ellipse at bottom,
    #1b2735 0%,
    $theme-background 100%
  );
  &.view {
    background-image: url('../../assets/img/bottle.jpg');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .star {
    background: transparent;
    position: relative;
    &:after {
      content: " ";
      z-index: -1;
      position: absolute;
      top: 2000px;
      background: transparent;
    }
  }
  #stars {
    width: 1px;
    height: 1px;
    -webkit-box-shadow: $shadows-small;
    -moz-box-shadow: $shadows-small;
    box-shadow: $shadows-small;
    animation: animStar 50s linear infinite;

    &:after {
      width: 1px;
      height: 1px;
      -webkit-box-shadow: $shadows-small;
      -moz-box-shadow: $shadows-small;
      box-shadow: $shadows-small;
    }
  }
  #stars2 {
    width: 2px;
    height: 2px;
    -webkit-box-shadow: $shadows-medium;
    -moz-box-shadow: $shadows-medium;
    box-shadow: $shadows-medium;
    animation: animStar 100s linear infinite;

    &:after {
      width: 2px;
      height: 2px;
      -webkit-box-shadow: $shadows-medium;
      -moz-box-shadow: $shadows-medium;
      box-shadow: $shadows-medium;
    }
  }
  #stars3 {
    width: 3px;
    height: 3px;
    -webkit-box-shadow: $shadows-big;
    -moz-box-shadow: $shadows-big;
    box-shadow: $shadows-big;
    animation: animStar 150s linear infinite;

    &:after {
      width: 3px;
      height: 3px;
      -webkit-box-shadow: $shadows-big;
      -moz-box-shadow: $shadows-big;
      box-shadow: $shadows-big;
    }
  }
  .auth-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-align-content: center;
    align-content: center;
    height: min(840px, calc(100vh - 90px));
    width: 100%;
    padding: 0 30px;
    overflow: hidden;
    .social-auth {
      padding: 15px;
      margin-right: auto;
      display: flex;
      flex-direction: column;

      .fail {
        align-items: center;
        align-self: center;
        .fail-wrapper {
          text-align: center;
          width: 90px;
          background: $theme-background-light;
          margin: 15px;
          padding: 8px;
          position: relative;
          border-radius: 5%;
          .card-img-top {
            display: block;
            width: 64px;
            height: 64px;
          }
          i {
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
      .btn-social {
        text-decoration: none;
        margin: 8px 20px 8px 8px;
        color: $theme-background;
        border-color: $theme-background;
        border: none;
        overflow: visible;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        position: relative;
        background: $theme-background-light;
        @include easeOut;
        &:last-child {
          margin-right: 0px;
        }
        &:before {
          content: "";
          display: inline-block;
          height: 100%;
          vertical-align: middle;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          border: 2px solid $theme-background;
          border-radius: 50%;
          transition: all 0.2s linear 0s;
        }
        &:hover {
          background: none;
          cursor: pointer;
          &:after {
            height: 0px;
            top: 100%;
          }
        }
        &:focus {
          outline: none;
        }
        &.facebook:hover {
          &:after {
            border: 2px solid $facebook;
          }
          i {
            color: $facebook;
          }
        }
        &.google:hover {
          &:after {
            border: 2px solid $google;
          }
          i {
            color: $google;
          }
        }
        &.twitter:hover {
          &:after {
            border: 2px solid $twitter;
          }
          i {
            color: $twitter;
          }
        }
        &.instagram:hover {
          &:after {
            border: 2px solid $instagram;
          }
          i {
            color: $instagram;
          }
        }
        &.weixin:hover {
          &:after {
            border: 2px solid $weixin;
          }
          i {
            color: $weixin;
          }
        }
        &.qq:hover {
          &:after {
            border: 2px solid $qq;
          }
          i {
            color: $qq;
          }
        }
        i {
          color: $theme-background;
          position: relative;
          z-index: 3;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
}

@include mediaSm {
  .auth {
    min-height: 100vh;
    height: 100%;
  }
  .auth-wrapper {
    padding: 0 !important;
    flex-wrap: wrap;
    justify-content: center;
    -webkit-justify-content: center;
    height: auto !important;
    .start {
      display: none;
    }
    .social-auth {
      padding: 8rem 4rem;
      display: flex;
      flex: 1 1 64px;
      flex-wrap: wrap;
      flex-direction: row !important;
      -webkit-flex-direction: row;
      justify-content: center;
      -webkit-justify-content: center;
      align-items: space-around;
      -webkit-align-items: space-around;
    }
  }
}
@keyframes animStar {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-2000px);
  }
}

/* email verification */
.verification {
  height: 50vh;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  // transform: translateY(50%);
  margin: 0 auto;
  border-radius: 2px;
  .verify-content {
    padding: 0 24px;
  }
}
.verification .password-reset {
  margin: 60px auto;
}
/* password-recover */
.password-recover .flex-center {
  text-align: center;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.password-recover .note {
  max-width: 300px;
  margin: 30px auto;
  text-align: center;
}
.password-recover .pwd-recover {
  max-width: 300px;
  margin: 30px auto;
  text-align: left;
}
.password-recover .pwd-recover .refresh {
  width: 25px;
  height: 25px;
}
.password-reset {
  text-align: left;
  padding: 0 24px;
}
.password-reset h3 {
  margin: 30px auto;
}
.password-reset .refresh {
  width: 25px;
  height: 25px;
}

/* login & register */
.customer-auth {
  flex: 0 0 360px;
  -webkit-flex: 0 0 360px;
  background: $theme-background-light;
  height: auto;
  padding: 30px;
  .auth-header {
    line-height: 8rem;
    text-align: center;
    font-size: 32px;
  }

  .btn-ctl .refresh {
    width: 25px;
    height: 25px;
  }
  .miscellaneous {
    margin: 30px auto;
    text-align: center;
  }
}
@include mediaSm {
  .customer-auth {
    margin: 0 auto;
    padding: 8px;
    margin-bottom: 40px;
  }
}