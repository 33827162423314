@import "../../styles/theme.scss";
@import "../../styles/animate.css";

.wrapper {
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
  }

  .scroll-item {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-shrink: 0;
    margin: 8px 12px;
    width: 140px;
    height: 200px;
    box-sizing: border-box;
    position: relative;
    position: relative;
    -webkit-transition: all 0.3s 0.12s;
    transition: all 0.3s 0.12s;
    opacity: 1;
    background-color: #fff;
    -webkit-box-shadow: 0 1.375rem 2.25rem -0.75rem rgba(64, 64, 64, 0.13);
    box-shadow: 0 1.375rem 2.25rem -0.75rem rgba(64, 64, 64, 0.13);
    &:last-child:after {
      content: "";
      width: 6vw;
      position: absolute;
      height: 100%;
      right: -6vw;
      top: 0;
    }
    &.first {
      margin-left: 6vw;
    }
    &.scale {
      opacity: 0.7;
      transform: scale(0.8);
      display: block;
    }
    &:hover {
      z-index: 3;
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
      -webkit-box-shadow: 0 1.375rem 2.25rem -0.75rem rgba(64, 64, 64, 0.7);
      box-shadow: 0 1.375rem 2.25rem -0.75rem rgba(64, 64, 64, 0.7);
      &:first-child {
        left: 2%;
      }
      &:nth-of-type(6) {
        right: 2%;
      }
    }
    .scroll-link {
      .scroll-header {
        position: relative;
        top: 0;

        .scroll-cover {
          width: 100%;
          height: 140px;
          object-fit: cover;
        }
        .scroll-score {
          position: absolute;
          bottom: 3px;
          right: 3px;
          font-size: 16px;
          color: #fff;
        }
      }
      .scroll-info {
        position: relative;
        padding: 8px;
        text-align: left;
        width: 100%;
        height: 60px;
        font-size: 12px;
        line-height: 0.7rem;
        .corner {
          position: absolute;
          right: 3px;
          bottom: 18px;
          width: 18px;
          height: 18px;
          p {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
