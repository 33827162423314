@import "../../styles/theme.scss";

.landing {
  margin-top: -60px;
  width: 100%;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.landing .slogon-box {
  position: absolute;
  padding: 8px;
  bottom: 15%;
  left: 60px;
  color: #fff;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.landing .slogon-box .slogon .btn-special {
  margin-top: calc(50% + 25px);
  position: relative;
  display: inline-block;
  width: 277px;
  height: 50px;
  font-size: 1em;
  font-weight: bold;
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 17px;
  letter-spacing: 0.045em;
}
.landing .slogon-box .slogon .btn-special svg {
  position: absolute;
  top: 0;
  left: 0;
}
.landing .slogon-box .slogon .btn-special svg rect {
  /* stroke: #EC0033; */
  stroke-width: 4;
  stroke-dasharray: 353, 0;
  stroke-dashoffset: 0;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
}
.landing .slogon-box .slogon .btn-special span {
  background: rgb(255, 173, 96);
  background: -moz-linear-gradient(
    left,
    rgba(255, 173, 96, 1) 0%,
    rgba(52, 58, 64, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 173, 96, 1) 0%,
    rgba(52, 58, 64, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 173, 96, 1) 0%,
    rgba(52, 58, 64, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8282', endColorstr='#e178ed',GradientType=1 );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.landing .slogon-box .slogon .btn-special:hover svg rect {
  stroke-width: 4;
  stroke-dasharray: 196, 543;
  stroke-dashoffset: 437;
}
.landing .slogon-box .caption {
  padding-top: 30px;
}
/* weekly news */
.news {
  padding: 4em 0px;
}
.news .news-main .news-left h2 {
  color: $text-color;
  font-size: 3em;
  width: 75%;
  margin: 2.5em auto;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-shadow: -1px -1px 1px #fff, -2px -2px 1px #fff;
}
.news .news-main .news-left .read-more {
  padding: 0 50px;
  text-align: right;
}
.news .news-main .news-left .read-more .read-more-link {
  color: $text-color;
  margin-right: 30px;
  text-decoration: none;
}
.news .news-main .news-left .read-more .read-more-link:hover {
  color: #ffad60;
}
.news .news-main .news-right p {
  color: $text-color-light;
  font-size: 16px;
  width: 85%;
  padding: 1em 0px;
  font-weight: 300;
  line-height: 1.9em;
}
/* statistics */
.stats {
  padding: 15px 0;
  // background: url("../../assets/img/man-night.jpg") no-repeat 0px 0px;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  background-attachment: fixed;
  .w3_counter_grid {
    text-align: center;
    margin: 2em 0px;
    float: left;
    .w3_agileits_counter_grid {
      background: $theme-background;
      width: 80px;
      height: 80px;
      border-radius: 10px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      margin: 0 auto;
      overflow: hidden;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -ms-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      i {
        color: $theme-color-4;
        font-size: 2em;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
      }
    }
    h3 {
      font-size: 1.2em;
      text-transform: uppercase;
    }
    p { 
      font-size: 4em;
      font-weight: 700;
      letter-spacing: 5px;
      margin: 0.5em 0;
    }
  }
}

/* Choose */
.choose {
  padding: 5em 0px;
  border-bottom: 1px solid #f1f1f1;
  .choose-main {
    .choose-left {
      h2 {
        color: $text-color;
        font-size: 3em;
        width: 70%;
        font-weight: 700;
        margin: 2em 0px 2em 1.3em;
        letter-spacing: 0.1rem;
        text-shadow: -1px -1px 1px #fff, -2px -2px 1px #fff;
      }
    }
    .choose-right {
      display: flex;
      flex-wrap: wrap;

      .choose-right-top {
        padding: 1.5rem;
        transition: all 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
          box-shadow: -4px -4px 3px #fff, -4px -4px 2px #fff;
          h4 {
            color: $theme-color-4;
            cursor: pointer;
          }
        }
        h4 {
          color: $text-color;
          font-size: 1.4em;
          margin: 0px;
          font-weight: 900;
          letter-spacing: 0.1rem;
          text-shadow: -1px -1px 1px #fff, -2px -2px 1px #fff;
        }
        p {
          color: $text-color-light;
          font-size: 16px;
          font-weight: 300;
          width: 85%;
          padding-top: 1em;
          line-height: 1.6em;
        }
      }
    }
  }
}
