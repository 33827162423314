@import "src/styles/theme.scss";

.feedback {
  background: $theme-background-light;
  .feedback-feedback {
    background: #fff;
    max-width: 1000px;
    padding: 30px;
    margin-top: 60px;
    .btn-ctl {
      background: #343a40;
      color: #fff;
      &:hover {
        color: #ffad60;
      }
    }
    .invalid-feedback {
      display: block;
      width: 100%;
      margin-top: 0.25rem;
      font-size: 14px;
      color: #dc3545;
    }
  }
  .space {
    height: 60px;
  }
}
