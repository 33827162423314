@import "src/styles/theme.scss";

/* access */
.access {
  .space {
    width: 100%;
    height: 60px;
  }
  &-access {
    background: #fff;
    max-width: 1000px;
  }
  ol {
    counter-reset: li; /* Initiate a counter */
    list-style: none; /* Remove default numbering */
    *list-style: decimal; /* Keep using default numbering for IE6/7 */
    padding: 0;
    margin-bottom: 4em;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  }

  ol ol {
    margin: 0 0 0 2em; /* Add some left margin for inner lists */
  }
  .rules li > span {
    position: relative;
    display: block;
    padding: 0.4em 0.4em 0.4em 2em;
    *padding: 0.4em;
    margin: 0.5em 0;
    background: #ddd;
    color: #444;
    text-decoration: none;
    border-radius: 0.3em;
    transition: all 0.3s ease-out;
  }
  .rules li > span:hover {
    background: #eee;
  }

  .rules li > span:hover:before {
    transform: rotate(360deg);
  }

  .rules li > span:before {
    content: counter(li);
    counter-increment: li;
    position: absolute;
    left: -1.3em;
    top: 50%;
    margin-top: -1.3em;
    background: $primary-color;
    height: 2em;
    width: 2em;
    line-height: 2em;
    border: 0.3em solid #fff;
    text-align: center;
    font-weight: bold;
    border-radius: 2em;
    transition: all 0.3s ease-out;
  }
}
