@import "../../styles/theme.scss";

.article {
  &.not-found {
    display: flex;
    height: 50vh;
    justify-content: center;
    align-items: center;
    p {
      color: $text-color;
    }
  }
  &.protect {
    background: radial-gradient(
          1.5em 6.28571em at 1.95em,
          rgba(255, 255, 255, 0) 50%,
          rgba(255, 255, 255, 0.25) 50%,
          rgba(255, 255, 255, 0.25) 55%,
          rgba(255, 255, 255, 0) 55%
        )
        0 0,
      radial-gradient(
          1.5em 6.28571em at -0.45em,
          rgba(255, 255, 255, 0) 50%,
          rgba(255, 255, 255, 0.25) 50%,
          rgba(255, 255, 255, 0.25) 55%,
          rgba(255, 255, 255, 0) 55%
        )
        1.5em 5.5em,
      radial-gradient(
          2.3em 4.57143em at 2.99em,
          rgba(255, 255, 255, 0) 50%,
          rgba(255, 255, 255, 0.3) 50%,
          rgba(255, 255, 255, 0.3) 55%,
          rgba(255, 255, 255, 0) 55%
        )
        0 0,
      radial-gradient(
          2.3em 4.57143em at -0.69em,
          rgba(255, 255, 255, 0) 50%,
          rgba(255, 255, 255, 0.3) 50%,
          rgba(255, 255, 255, 0.3) 55%,
          rgba(255, 255, 255, 0) 55%
        )
        2.3em 4em,
      radial-gradient(
          3.5em 6.28571em at 4.55em,
          rgba(255, 255, 255, 0) 50%,
          rgba(255, 255, 255, 0.25) 50%,
          rgba(255, 255, 255, 0.25) 55%,
          rgba(255, 255, 255, 0) 55%
        )
        0 0,
      radial-gradient(
          3.5em 6.28571em at -1.05em,
          rgba(255, 255, 255, 0) 50%,
          rgba(255, 255, 255, 0.25) 50%,
          rgba(255, 255, 255, 0.25) 55%,
          rgba(255, 255, 255, 0) 55%
        )
        3.5em 5.5em,
      radial-gradient($theme-background-light, $theme-background);
    background-color: $theme-background;
    background-size: 1.5em 11em, 1.5em 11em, 2.3em 8em, 2.3em 8em, 3.5em 11em,
      3.5em 11em, 100% 100%;
    background-repeat: repeat;
  }

  .article-category-picture {
    width: 100%;
    min-height: 440px;
    max-height: 500px;
    margin: 0 auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .article-article {
    max-width: 1000px;
    margin-top: -60px;
    padding: 8px 15px;
    background: #fff;
    position: relative;
    top: 0;

    .article-origin {
      position: absolute;
      right: 8px;
      top: 12px;
      .origin-icon {
        width: 36px;
        height: 36px;
        transform: scaleX(-1);
      }
      .origin-author,
      .origin-author-link {
        font-size: 24px;
        color: #000;
      }
    }
    .article-seo {
      .article-tags {
        height: auto;
        width: 100%;

        .tag-item {
          float: left;
          margin-right: 12px;
          &:before {
            content: "#";
          }
        }
      }
    }
  }
  .rating-article {
    max-width: 1000px;
    padding: 15px 8px;
    height: 60px;
  }
  .member-articles {
    max-width: 1000px;
    margin: 15px auto;
    min-height: 280px;
    padding: 8px;
    h2 {
      margin: 15px auto;
    }
  }
  .comment-article {
    max-width: 1000px;
    padding: 15px 8px;
    background: #fff;

    .comment-box {
      max-width: 600px;
      margin: 15px 0;

      textarea {
        width: 100%;
        border: 1px solid $theme-background;
      }
      .btn-comment {
        background: $theme-background;
        color: #fff;
        &:hover {
          color: $theme-color-4;
        }
      }
    }
    .comment-list {
      text-align: right;

      .list-unstyled {
        text-align: left;

        .comment-list-item {
          margin-bottom: 15px;
          transition: all 0.4s ease-out;

          .comment-left {
            img {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
  .space {
    height: 60px;
  }
}

@include mediaMd {
  .article-article {
    h2 {
      font-size: 24px;
      font-weight: bold;
      margin: 15px auto;
    }
    .origin-author-link {
      font-size: 20px !important;
    }
  }
  .member-articles {
    h2 {
      font-size: 24px;
    }
  }
}

@include mediaSm {
  .article-article {
    h2 {
      font-size: 20px;
      font-weight: bold;
      margin: 15px auto;
    }
    .origin-author-link {
      font-size: 16px !important;
    }
  }
  .member-articles {
    h2 {
      font-size: 20px;
    }
  }
}
