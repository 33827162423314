@import "../../styles/theme.scss";
@import "../../styles/animate.css";

.table-moden {
  max-width: 1000px;
  margin: 15px auto;

  .responsive-table {
    li {
      width: 100%;
      padding: 15px 25px;
      margin-bottom: 15px;
      position: relative;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      animation: fadeInRight 1s cubic-bezier(0.175, 0.885, 0.32, 1.18);
      -webkit-animation: fadeInRight 1s cubic-bezier(0.175, 0.885, 0.32, 1.18);

      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          animation-delay: $i * (0.2s);
        }
      }
      &:first-child {
        background: lighten($theme-background, 15%);
        background: -webkit-linear-gradient(
          to left,
          lighten($theme-background, 15%),
          $theme-background
        );
        .circles {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
        }

        .circles span {
          position: absolute;
          display: block;
          list-style: none;
          width: 20px;
          height: 20px;
          background: rgba(255, 255, 255, 0.2);
          animation: animate 25s linear infinite;
          bottom: -150px;
        }

        .circles span:nth-child(1) {
          left: 25%;
          width: 80px;
          height: 80px;
          animation-delay: 0s;
        }

        .circles span:nth-child(2) {
          left: 10%;
          width: 20px;
          height: 20px;
          animation-delay: 2s;
          animation-duration: 12s;
        }

        .circles span:nth-child(3) {
          left: 70%;
          width: 20px;
          height: 20px;
          animation-delay: 4s;
        }

        .circles span:nth-child(4) {
          left: 40%;
          width: 60px;
          height: 60px;
          animation-delay: 0s;
          animation-duration: 18s;
        }

        .circles span:nth-child(5) {
          left: 65%;
          width: 20px;
          height: 20px;
          animation-delay: 0s;
        }

        .circles span:nth-child(6) {
          left: 75%;
          width: 110px;
          height: 110px;
          animation-delay: 3s;
        }

        .circles span:nth-child(7) {
          left: 35%;
          width: 150px;
          height: 150px;
          animation-delay: 7s;
        }

        .circles span:nth-child(8) {
          left: 50%;
          width: 25px;
          height: 25px;
          animation-delay: 15s;
          animation-duration: 45s;
        }

        .circles span:nth-child(9) {
          left: 20%;
          width: 15px;
          height: 15px;
          animation-delay: 2s;
          animation-duration: 35s;
        }

        .circles span:nth-child(10) {
          left: 85%;
          width: 150px;
          height: 150px;
          animation-delay: 0s;
          animation-duration: 11s;
        }
      }
    }
    .table-header {
      background-color: $theme-background;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      color: #fff;
      & > div {
        flex-wrap: wrap;
      }
    }
    .table-row {
      background-color: #ffffff;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
    }
    .coll-0 {
      flex-basis: 20%;
      -webkit-flex-basis: 20%;
    }
    .coll-1 {
      flex-basis: 30%;
      -webkit-flex-basis: 30%;
    }
    .coll-2 {
      flex-basis: 25%;
      -webkit-flex-basis: 25%;
    }
    .coll-3 {
      flex-basis: 10%;
      -webkit-flex-basis: 10%;
    }
    .coll-4 {
      flex-basis: 15%;
      -webkit-flex-basis: 15%;
    }
    @include mediaSm {
      li {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        &:first-child {
          display: none;
        }
      }
      .coll {
        display: flex;
        &:before {
          color: #6c7a89;
          padding-right: 10px;
          content: attr(data-label);
          text-align: right;
          flex-basis: 50%;
        }
      }
    }
  }
}
@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
@keyframes up {
  0% {
    opacity: 0;
  }
  10%,
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-1024px);
  }
}
