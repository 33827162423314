@import "../../styles/theme.scss";

.nav-transparent {
  z-index: 1;
  padding: 0 1rem;
  height: 60px;
  .nav-logo {
    width: auto;
    height: 40px;
  }
  .nav-avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .navbar-collapse {
    &.show {
      .navbar-nav .nav-item {
        background: rgba(64, 64, 64, 0.3);
        text-align: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.7);
        &:hover {
          background: rgba(64, 64, 64, 0.4);
        }
      }
    }
    .navbar-nav {
      position: relative;
      text-align: center;
      vertical-align: middle;
      & > * {
        position: relative;
        z-index: 5;
      }
      @for $num from 1 through 7 {
        .item-#{$num} {
          &:hover {
            &:before {
              width: 100%;
            }
          }
        }
      }
      .nav-item {
        background: transparent;
        -webkit-transition: all 0.3s 0.12s;
        transition: all 0.3s 0.12s;
        &:before {
          content: "";
          display: block;
          position: absolute;
          bottom: -2px;
          left: 0px;
          width: 0px;
          height: 2px;
          transition: all 0.4s ease 0s;
          background: -webkit-linear-gradient(left, #343a40, #ffad60);
        }
        &:hover {
          .nav-link {
            color: $theme-color-4;
          }
        }
        &.avatar {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &.empty {
          width: 40px;
        }
        .nav-link {
          padding: 16px 10px;
          font-size: 14px;
          color: #fff;
          -webkit-transition: all 0.3s 0.12s;
          transition: all 0.3s 0.12s;
          &.active {
            color: $theme-color-4;
          }
        }
      }
    }
    .drop-transparent {
      button,
      a.dropdown-item {
        color: #fff;
        &:hover {
          color: $theme-color-4;
        }
        &.active {
          color: $theme-color-4;
        }
      }
    }
  }
  &.active {
    z-index: 1;
    background: rgba($color: #000000, $alpha: 0) !important;

    .drop-transparent {
      background: rgba($color: #000000, $alpha: 0.3) !important;
      border: none;
      a:hover {
        color: $theme-color-4;
        background: $theme-background;
      }
    }
  }
}

@include mediaMd {
  .nav-transparent .navbar-collapse .navbar-nav .nav-item {
    &.empty {
      display: none;
    }
    &.avatar {
      padding: 8px 10px;
    }
    .nav-link {
      padding: 8px 10px;
    }
  }
}
@include mediaSm {
  .nav-transparent .navbar-collapse .navbar-nav .nav-item {
    &.empty {
      display: none;
    }
    &.avatar {
      padding: 8px 10px;
    }
    .nav-link {
      padding: 8px 10px;
    }
  }
}