.menu-nav {
  .side-drop {
    list-style: none;
    margin: 0;
    padding: 0;
    background: #343a40;
  }
  .nav-text {
    display: none;
    color: #fff;
    -webkit-transition-delay: 2s;
    transition-delay: 2s;
    transition: all 0.5s ease-out;
    &.show {
      display: inline;
    }
  }
  .nav-link {
    display: inline-block;
    position: relative;
    font-size: 16px;
    width: 100%;
    text-transform: uppercase;
    padding: 1rem 0;
    text-decoration: none;
    color: #ffe0bd;
  }
  .nav-link:hover i,
  .nav-link:hover span {
    color: #ffad60;
  }
  .nav-link.active i,
  .nav-link.active span {
    color: #ffad60;
  }
}
