@import "src/styles/theme.scss";

/* about */
.about {
  background: $theme-background-light;
  .about-about {
    background: #fff;
    max-width: 1000px;
    .img-about {
      width: 100%;
    }
    .goals {
      padding: 15px;
    }
    .team {
      padding: 0 15px 15px 15px;
    }
    .help {
      padding: 0 15px 15px 15px;
    }
  }
  .space {
    height: 60px;
  }
}
.policy {
  .policy-policy {
    background: #fff;
    max-width: 1000px;
    margin-top: 60px;
    padding: 30px;
    .privacy-tab {
      margin: 15px 0;
    }
  }

  .shield {
    position: relative;
    bottom: 3rem;
    left: 50%;
  }
  .space {
    height: 60px;
  }
}