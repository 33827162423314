@import "../../../styles/theme.scss";
@import "../../../styles/animate.css";

.account {
  .dropZone {
    width: 100%;
    height: 200px;
    margin: 20px 0;
    text-align: center;
    border: 3px dashed #57554a;
    border-radius: 8px;
    padding: 0;
    position: relative;
    top: 0;
    .select-area {
      padding: 15px;
      width: 100%;
      height: 100%;
      &:hover {
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }
    }
    .btn-select {
      position: absolute;
      bottom: 8px;
      transform: translateX(-50%);
    }
  }
  .avatarEditor {
    .avatar-editor {
      border: 3px dashed #57554a;
      border-radius: 8px;
    }

    .slider {
      -webkit-appearance: none;
      appearance: none;
      margin-left: 8px;
      width: 85%;
      height: 15px;
      background: $theme-background;
      outline: none;
      opacity: 0.7;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
    }
    .slider:hover {
      opacity: 1;
    }
    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 15px;
      height: 15px;
      background: $theme-color-4;
      cursor: pointer;
    }

    .slider::-moz-range-thumb {
      width: 15px;
      height: 15px;
      background: $theme-color-4;
      cursor: pointer;
    }
  }
  .dangerous-opt {
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 15rem;
    text-align: center;
    overflow: hidden;
    border: 3px dashed #dc3545;
    border-radius: 12px;
  }
}
