@import "../../styles/theme.scss";
@import "../../styles/animate.css";

.profile {
  background: $theme-background-light;
  .profile-body {
    max-width: 1000px;
    padding: 8px;
    background: #fff;
    margin-top: -30px;
    .profile-detail {
      margin-bottom: 30px;

      .normal-intro {
        margin-bottom: 8px;
        .avatar {
          position: relative;
          width: 100%;
          aspect-ratio: 1;
          overflow: hidden;
          .img-fluid {
            width: 100%;
            display: inline-block;
            margin-bottom: 0.5rem;
          }
          .online {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 10%;
            color:  $primary-color;
            background-color: rgba(0, 0, 0, 0.22);
            padding-left: 16px;
            display: flex;
            align-items: center;
            &:before {
              content: "";
              width: 8px;
              height: 8px;
              display: block;
              margin-right: 8px;
              border-radius: 50%;
              background: radial-gradient(circle at 65% 15%, white 1px, aqua 3%, green 60%, aqua 100%)
            }
          }
        }
        span {
          padding-right: 8px;
        }
        .name-info {
          font-size: 16px;
          color: #343a40;
        }
        .age-info {
          color: grey;
        }
        .female {
          color: pink;
        }
        .male {
          color: blue;
        }
      }
      .lang-intro {
        overflow: auto;
        margin-bottom: 8px;
        &:before,
        &:after {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
        .lang-item {
          float: left;
          background: #343a40;
          color: #fff;
          margin: 5px 8px 5px 0;
          padding: 3px;
        }
      }
      .social-intro {
        &:before,
        &:after {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
        p {
          margin-bottom: 0;
        }
        a {
          float: left;
        }
      }
      .hobbies-intro {
        p {
          margin-bottom: 0;
        }
        .read-more-state {
          display: none;

          &:checked {
            & ~ .read-more:before {
              content: attr("data-less");
            }
            & ~ .read-more-wrapper .read-more-target {
              opacity: 1;
              font-size: inherit;
              max-height: 999em;
            }
          }
        }
        .read-more-wrapper {
          &:before,
          &:after {
            content: "";
            display: table;
          }
          &:after {
            clear: both;
          }
        }
        .hobby-item {
          background: #343a40;
          color: #fff;
          float: left;
          margin: 5px 8px 5px 0;
          padding: 3px;
        }
        .read-more-target {
          opacity: 0;
          max-height: 0;
          font-size: 0;
          @include easeOut;
          animation: bounce 0.3s linear;
        }
        .read-more {
          cursor: pointer;
          color: $theme-background;
          font-size: 0.9em;
          width: 100%;
          height: 16px;
          &:before {
            content: attr("data-more");
            display: block;
          }
        }
      }
      .friend-list {
        &:before,
        &:after {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
        .friend {
          float: left;
          padding: 5px 8px 5px 0;
          text-align: center;
        }
      }
      .profile-operations {
        .opt {
          .tip {
            width: 140px;
            margin-right: 8px;
          }
          .btn {
            width: 140px;
            color: #fff;
            background: $theme-background;
            margin-right: 2%;
            margin-bottom: 8px;
            &:hover {
              color: $theme-color-4;
            }
          }
          .btn-chat {
            &:hover {
              color: #fff;
            }
          }
          .btn-abuse {
            background: red;
          }
        }
      }
    }
    .profile-content {
      .activity-item {
        width: 100%;
        padding: 8px;
        overflow: hidden;
        margin-bottom: 15px;
        .activity-avatar {
          position: relative;
          left: 55px;
          margin-top: 15px;
          width: auto;
          height: 150px;
        }
        .activity-cover {
          position: relative;
          left: 55px;
          margin-top: 15px;
          max-width: 80%;
          height: 150px;
        }
      }
    }
  }
  .space {
    height: 60px;
  }
}
