input {
  outline: none;
  border: none;
}
input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}
input::-webkit-input-placeholder {
  color: #adadad;
}
input:-moz-placeholder {
  color: #adadad;
}
input::-moz-placeholder {
  color: #adadad;
}
input:-ms-input-placeholder {
  color: #adadad;
}
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  background: transparent !important;
}
.form-modern-group {
  display: block;
  position: relative;
  margin-bottom: 37px;
  border-bottom: 2px solid #b7b7b7;
}
.form-modern-group .icon {
  font-size: 15px;
  color: rgb(153, 153, 153);
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0px;
  right: 0px;
  padding-right: 5px;
  cursor: pointer;
  transition: all 0.4s ease 0s;
}
.form-modern-group .form-modern {
  font-size: 15px;
  color: #495057;

  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
  padding: 0 5px;
}
.is-invalid:before {
  content: attr(data-error);
  position: absolute;
  max-width: 70%;
  background-color: rgb(255, 255, 255);
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  pointer-events: none;
  font-family: Poppins-Regular;
  color: rgb(200, 0, 0);
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  transition: opacity 0.4s ease 0s;
}
.is-invalid:after {
  content: "\26a0";
  font-size: 16px;
  color: rgb(200, 0, 0);
  display: block;
  position: absolute;
  background-color: rgb(255, 255, 255);
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
}
.form-modern-group .form-modern:focus {
  overflow: visible;
}
.form-modern.hasVal + .modern-title:before {
  width: 100%;
}
.form-modern.hasVal + .modern-title:after {
  top: -15px;
}
.form-modern-group .modern-title {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  pointer-events: none;
}
.form-modern-group .modern-title:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0px;
  width: 0px;
  height: 2px;
  transition: all 0.4s ease 0s;
  background: -webkit-linear-gradient(left, #343a40, #ffad60);
}
.form-modern-group .modern-title:after {
  font-size: 15px;
  color: rgb(153, 153, 153);
  line-height: 1.2;
  content: attr(data-title);
  display: block;
  width: 100%;
  position: absolute;
  top: 14px;
  left: 0px;
  padding-left: 5px;
  transition: all 0.4s ease 0s;
}
.form-modern-group .modern-info {
  position: absolute;
  bottom: -1.3rem;
  color: #b7b7b7;
}
