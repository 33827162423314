@import url("../../styles/theme.scss");

.dashboard-page, .profile {
  .dashboard-cover {
    width: 100%;
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    color: #fff;
  }
  .dashboard-cover .col {
    height: 300px;
    position: relative;
    top: 0;
  }
  .dashboard-cover .col .member {
    position: absolute;
    bottom: 30%;
  }
  .dashboard-cover .col .handle {
    position: absolute;
    bottom: 20%;
  }
  .dashboard-cover .col .member-badge {
    position: absolute;
    bottom: 30%;
    right: 15px;
  }
  .page-sidebar {
    position: relative;
    top: 0;
    left: 0;
  }
  .page-sidebar i {
    color: #fff;
  }
  .page-sidebar .ctl-btn {
    color: #ffad60;
  }
  
  .menu-btn {
    position: absolute;
    font-size: 22px;
    left: 17px;
    top: -35px;
    cursor: pointer;
    transition: all 0.5s ease-out;
  }
  .menu-btn.close {
    transform: rotate(180deg);
  }
  
  .menu {
    opacity: 0.9;
    display: flex;
    flex-flow: column wrap;
    float: left;
    width: 46px;
    overflow: hidden;
    background: #292d32;
    list-style: none;
    transition: all 0.5s ease-out;
  }
  .menu .menu-nav .side-drop {
    list-style: none;
    margin: 0;
    padding: 0;
    background: #343a40;
  }
  .menu .menu-nav .nav-text {
    display: none;
    color: #fff;
    -webkit-transition-delay: 2s;
    transition-delay: 2s;
    transition: all 0.5s ease-out;
  }
  .menu .menu-nav .nav-text.show {
    display: inline;
  }
  .menu .nav-link {
    display: inline-block;
    position: relative;
    font-size: 16px;
    width: 100%;
    text-transform: uppercase;
    padding: 1rem 0;
    text-decoration: none;
    color: #ffe0bd;
  }
  .menu .nav-link:hover i,
  .menu .nav-link:hover span {
    color: #ffad60;
  }
  .menu .nav-link.active i,
  .menu .nav-link.active span {
    color: #ffad60;
  }
  .menu .nav-link.active.inactive i,
  .menu .nav-link.active.inactive span {
    color: #fff;
  }
  

  .dashboard-content {
    max-width: 900px;
    margin: 0 auto;
  }
  .dashboard-content .content .activity .list-unstyled .activity-item {
    width: 100%;
    padding: 8px;
    overflow: hidden;
    margin-bottom: 15px;
  }
  .dashboard-content
    .content
    .activity
    .list-unstyled
    .activity-item
    .activity-avatar {
    position: relative;
    left: 55px;
    margin-top: 15px;
    width: auto;
    height: 150px;
  }
  .dashboard-content
    .content
    .activity
    .list-unstyled
    .activity-item
    .activity-cover {
    position: relative;
    left: 55px;
    margin-top: 15px;
    max-width: 80%;
    height: 150px;
  }
  .dashboard-content .friend .friend-request {
    margin-bottom: 15px;
  }
  .dashboard-content .friend .friend-request .request-item {
    position: relative;
    top: 0;
    margin-bottom: 15px;
    width: 100%;
  }
  .dashboard-content .friend .friend-request .request-item .request-people {
    float: left;
  }
  .dashboard-content .friend .friend-request .request-item .request-info {
    margin: 0 75px;
  }
  .dashboard-content
    .friend
    .friend-request
    .request-item
    .request-info
    .invitation {
    color: #343a40;
  }
  .dashboard-content .friend .friend-request .request-item .ctl {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
  }
  .dashboard-content .friend .friend-request .request-item .ctl .btn-ctl {
    background: #343a40;
    color: #fff;
    margin: 2px;
    min-width: 6rem;
  }
  .dashboard-content .friend .friend-request .request-item .ctl .btn-ctl:hover {
    color: #ffad60;
  }
  .dashboard-content .friend .friend-list {
    margin-bottom: 15px;
  }
  .dashboard-content .friend .friend-list .friend-item {
    width: 100%;
    margin: 8px 0;
  }
  .dashboard-content .friend .friend-list .friend-item .friend-avatar {
    float: left;
  }
  .dashboard-content .friend .friend-list .friend-item .friend-info {
    margin: 0 75px;
  }
  .dashboard-content .friend .friend-list .friend-item .friend-info .age-info {
    font-size: 12px;
  }
  .dashboard-content .friend .friend-list .friend-item .friend-info .female {
    color: pink;
  }
  .dashboard-content .friend .friend-list .friend-item .friend-info .male {
    color: blue;
  }
  .dashboard-content .friend .friend-list .friend-item .ctl {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
  }
  .dashboard-content .friend .friend-list .friend-item .ctl .btn-ctl {
    background: #343a40;
    color: #fff;
    margin: 2px;
    min-width: 6rem;
  }
  .dashboard-content .friend .friend-list .friend-item .ctl .btn-ctl:hover {
    color: #ffad60;
  }

  /* Article Create */
  .article-crate {
    padding: 15px 0;
  }
  .editor-draft {
    min-height: 80vh;
    background: #fff;
    padding: 0 8px;
  }
}
