.select-box {
  margin-bottom: 15px;
}
.box_btn {
  background: #343a40;
  margin-right: 8px;
  padding: 8px;
  color: #fff;
  margin-bottom: 15px;
}
.box_btn.active {
  color: #ffad60;
}
.box_btn.active.inactive {
  color: #fff;
}
.message-content {
  margin-top: 15px;
  margin-bottom: 60px;
}
.filter_results {
  margin-bottom: 8px;
}
.target-item {
  width: 144px;
  float: left;
  margin-right: 15px;
}
.editor-message {
  min-height: 120px;
  padding: 8px;
  border: 1px solid #ced4da;
}
.btn-ctl {
  background: #343a40;
  color: #fff;
}
.btn-ctl:hover {
  color: #ffad60;
}
.img-msg-circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.message_single {
  background: url("../../../assets/img/flowers.jpg");
  background-repeat: repeat-y;
  background-size: cover;
  padding: 8px;
}
.signature {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 120px;
}
.sign {
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 8px;
}
.sign-name {
  font-size: 22px;
  margin-left: 8px;
}
.sign-date {
  margin: 8px 0;
}
