@import "../../../styles/theme.scss";
@import "../../../styles/animate.css";

.settings {
  .settings-item {
    padding: 8px;
    margin-bottom: 15px;
    .profile-hobbies .hobby-fields {
      width: 100%;

      .hobby-item {
        float: left;
        padding: 8px;
        margin: 8px 8px 8px 0;
        background: #343a40;
        color: #fff;
        @include easeOut;
        animation: slideInUp 0.3s linear;

        i {
          margin-left: 8px;
          &:hover {
            color: #ffad60;
          }
        }
      }
    }
    .profile-cover {
      .selectPic {
        width: 100%;
        .tick {
          width: 25%;
          float: left;
          position: relative;
          left: 0;
          padding: 8px 8px 8px 0px;
          &.active {
            .img-cover {
              border: 2px solid #ffad60;
            }
          }
          &:hover {
            .img-cover {
              border: 2px solid #ffad60;
            }
          }
          .gly {
            display: none;
            &.active {
              color: #ffad60;
              display: block;
              position: absolute;
              top: 15px;
              right: 20px;
            }
          }
          .img-cover {
            width: 100%;
          }
        }
      }
      .clear-text {
        clear: both;
      }
    }
    .profile-language {
      .lang-fields {
        width: 100%;

        .lang-item {
          float: left;
          padding: 8px;
          margin: 8px 8px 8px 0;
          background: #343a40;
          color: #fff;
          @include easeOut;
          animation: slideInUp 0.3s linear;

          span {
            padding-right: 0.5rem;
          }

          i {
            margin-left: 1rem;
            &:hover {
              color: #ffad60;
            }
          }
        }
      }
      .clear-fix {
        clear: both;
      }
    }
    .btn-set {
      color: #fff;
      background: #343a40;
      &:hover {
        color: #ffad60;
      }
    }
  }
}
