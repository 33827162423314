@import "./styles/theme.scss";

// body {
//   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // font-weight: normal;
}
a {
  line-height: 1.7;
  color: $theme-background;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  color: $theme-color-4;
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  transition:
    color 0.5s,
    background-color 0.5s;
  line-height: 1.6;
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  font-size: 15px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

blockquote,
dl,
dd,
hr,
figure,
pre {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-size: inherit;
  font-weight: inherit;
}

/**
 * Avoid 300ms click delay on touch devices that support the `touch-action`
 * CSS property.
 *
 * In particular, unlike most other browsers, IE11+Edge on Windows 10 on
 * touch devices and IE Mobile 10-11 DON'T remove the click delay when
 * `<meta name="viewport" content="width=device-width">` is present.
 * However, they DO support removing the click delay via
 * `touch-action: manipulation`.
 *
 * See:
 * - http://v4-alpha.getbootstrap.com/content/reboot/#click-delay-optimization-for-touch
 * - http://caniuse.com/#feat=css-touch-action
 * - http://patrickhlauke.github.io/touch/tests/results/#suppressing-300ms-delay
 */
a,
area,
button,
[role='button'],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

a {
  // color: inherit;
  text-decoration: inherit;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

pre,
code,
kbd,
samp {
  font-family: var(--vt-font-family-mono);
}

svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

video {
  max-width: 100%;
  height: auto;
}

button,
input,
optgroup,
select,
textarea {
  border: 0;
  padding: 0;
  line-height: inherit;
  color: inherit;
}

button {
  padding: 0;
  background-color: transparent;
  background-image: none;
}

button,
[role='button'] {
  cursor: pointer;
}

button:focus,
button:focus-visible {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button:focus:not(:focus-visible) {
  outline: none !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

// table {
//   border-collapse: collapse;
// }

input {
  background-color: transparent;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: var(--vt-c-text-3);
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: var(--vt-c-text-3);
}

input::placeholder,
textarea::placeholder {
  color: var(--vt-c-text-3);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

textarea {
  resize: vertical;
}

select {
  -webkit-appearance: none;
}

fieldset {
  margin: 0;
  padding: 0;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media (min-width: 1024px) {
}
.mr-auto {
  margin-right: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.m-auto {
  margin: 0 auto;
}
.mt-8 {
  margin-top: 0.5rem;
}
.mb-8 {
  margin-bottom: 0.5rem;
}
.ml-8 {
  margin-left: 0.5rem;
}
.mr-8 {
  margin-right: 0.5rem;
}
.mt-16 {
  margin-top: 1rem;
}
.mb-16 {
  margin-bottom: 1rem;
}
.ml-16 {
  margin-left: 1rem;
}
.mr-16 {
  margin-right: 1rem;
}
.pl-0 {
  padding-left: 0;
}
.p-8 {
  padding: 0.5rem;
}
.pt-8 {
  padding-top: 0.5rem;
}
.pb-8 {
  padding-bottom: 0.5rem;
}
.pl-8 {
  padding-left: 0.5rem;
}
.pr-8 {
  padding-right: 0.5rem;
}
.p-16 {
  padding: 1rem;
}
.pt-16 {
  padding-top: 1rem;
}
.pb-16 {
  padding-bottom: 1rem;
}
.pl-16 {
  padding-left: 1rem;
}
.pr-16 {
  padding-right: 1rem;
}

.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.grow-0 {
  flex-grow: 0;
}
.grow-1 {
  flex-grow: 1;
}
.shrink-0 {
  flex-shrink: 0;
}
.shrink-1 {
  flex-shrink: 1;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-column {
  flex-direction: column;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-evenly {
  justify-content: space-evenly;
}
.align-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}
.align-self-start {
  align-self: flex-start;
}
.cursor {
  cursor: pointer;
}
.circle {
  border-radius: 50%;
}
.theme {
  color: $primary-color;
}
.text-center {
  text-align: center;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-magic {
  font-size: 40px;
  color: $text-color;
}
.text-white {
  color: white;
}
.text-main {
  color: $text-color;
}
.text-normal {
  color: $text-color-light;
}
.text-male {
  color: $male;
}
.text-female {
  color: $female;
}
.text-small {
  font-size: 13px;
  color: #888888;
}
.text-red {
  color: red;
}
.ft-32 {
  font-size: 32px;
}
.ft-22 {
  font-size: 22px;
}
.ft-18 {
  font-size: 18px;
}
.ft-16 {
  font-size: 16px;
}
.ft-13 {
  font-size: 13px;
}
.unselect {
  user-select: none;
}
.btn-block {
  display: block;
  width: 100%;
}
.page-height {
  height: 100%;
  min-height: 100vh;
}
.sticky {
  position: sticky;
}
.relative {
  position: relative;
}
.divider {
  color: rgb(152 162 179);
  flex-shrink: 0;
  width: 0.25rem;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}
.pipe-divider {
  height: 14px;
  width: 1px;
  background-color: rgb(234 236 240);
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.main {
  margin-top: -60px;
  padding: 0;
  min-height: 100vh;
  background: $theme-background-light;
  .img-media {
    width: 64px;
    height: 64px;
  }
  .img-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .img-flag {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    &.rect {
      width: 1.5rem;
      height: auto;
      border-radius: 0;
      vertical-align: middle;
    }
  }
  .img-circle-bg {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
  .pull-right {
    float: right;
  }
}
footer {
  clear: both;
}
.dropdown-menu {
  padding: 0;
}
@media screen and (min-width: 1171px) {
  .menu.show {
    width: 180px;
  }
  .dashboard-content {
    padding: 30px;
    transition: all 0.5s ease-out;
    // margin-left: 46px;
  }
  .dashboard-content.show {
    // margin-left: 180px;
  }
  .dashboard-content .lift {
    margin-top: -40px;
  }
  .dashboard-content .search-box {
    width: 80%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1170px) {
  .landing {
    background-position-x: 60%; 
  }
  .menu.show {
    width: 180px;
  }
  .dashboard-content {
    padding: 15px 15px 30px 15px;
    transition: all 0.5s ease-out;
    // margin-left: 46px;
  }
  .dashboard-content.show {
    // margin-left: 180px;
  }
  .dashboard-content .lift {
    margin-top: -40px;
  }
  .dashboard-content .search-box {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .landing {
    background-position-x: 70%; 
  }
  .menu.show {
    width: 180px;
  }
  .dashboard-content {
    padding: 15px 15px 30px 15px;
    transition: all 0.5s ease-out;
    margin-left: 46px;
  }
  .dashboard-content.show {
    margin-left: 180px;
  }
  .dashboard-content .lift {
    margin-top: 20px;
  }
  .dashboard-content .search-box {
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .menu.show {
    position: absolute;
    left: 0;
    z-index: 1;
  }
  .dashboard-content.show {
    position: fixed;
    // margin-left: 46px;
  }
  .dashboard-content .hide {
    display: none;
  }
  .dashboard-content .img-circle-sbg {
    width: 48px;
    height: 48px;
  }
  .dashboard-content .search-box {
    width: 100%;
  }
}
