$theme_color_1: #ffe0bd;
$theme_color_2: #ffcd94;
$theme_color_3: #eac086;
$theme_color_4: #ffad60;
$theme_color_5: #ffe39f;

$primary-color: $theme_color_2;

$theme-background: #343a40;
$theme-background-light: #f9f9f9;

$text-color: #727272;
$text-color-light: #b7b7b7;

$facebook: #3b5998;
$twitter: #1da1f2;
$google: #dd4b39;
$instagram: #c32aa3;
$weixin: #09b83e;
$qq: #ffad60;
$font-header: 18px;
$font-size: 16px;

$female: #e6a6c7;
$male: #347dc1;

@mixin easeOut {
  transition: all 0.5s ease-out;
}

@mixin easeIn {
  transition: all 0.5s ease-in;
}
@mixin abs_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin abs_middle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
// set text color
@function set-text-color($color) {
  @if (lightness($color) > 40) {
    @return #000;
  } @else {
    @return #fff;
  }
}

$shadows-small: multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);
@function multiple-box-shadow($n) {
  $value: "#{random(2000)}px #{random(2000)}px #FFF";
  @for $i from 2 through $n {
    $value: "#{$value} , #{random(2000)}px #{random(2000)}px #FFF";
  }
  @return unquote($value);
}

@mixin mediaSm {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin mediaMd {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin mediaLg {
  @media screen and (min-width: 769px) and (max-width: 1170px) {
    @content;
  }
}
@mixin mediaXL {
  @media screen and (min-width: 1171px) {
    @content;
  }
}
